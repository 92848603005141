import React, { useState, useRef } from "react"
import { Link } from "gatsby"
// nodejs library that concatenates classes
import classNames from "classnames"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Button from "@components/customButtons/button.js"
import {
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Drawer,
  InputBase,
  Collapse,
  FormControl,
} from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import GridContainer from "@components/Grid/GridContainer.js"
import GridItem from "@components/Grid/GridItem.js"
import { FormControlLabel, Switch, FormHelperText } from "@material-ui/core"

// @material-ui/icons
import Menu from "@material-ui/icons/Menu"
// core components
import styles from "assets/jss/components/layout/header/style.js"

import ThreeMysticApesThemeHelper from "three_mystic_apes_extension/util/themeHelper.js"

const useStyles = makeStyles(styles)

export default function PageHeader({ classes, classesPage, ...props }) {
  const themeVariation = ThreeMysticApesThemeHelper.GetThemeVariation()
  const pageHeaderclasses = useStyles()
  const [mobileOpen, setMobileOpen] = useState(false)
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange)
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange)
      }
    }
  })
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props
    const windowsScrollTop = window.pageYOffset
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(pageHeaderclasses[color])
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(pageHeaderclasses[changeColorOnScroll.color])
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(pageHeaderclasses[color])
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(pageHeaderclasses[changeColorOnScroll.color])
    }
  }

  const searchIconClick = e => {
    if (!searchOpenCollapseIn) {
      const searchIconBounding = e.target.getBoundingClientRect()
      const searchBoxBounding = searchBoxContainerRef.current.getBoundingClientRect()
      const searchNavBounding = searchNavRef.current.getBoundingClientRect()

      searchBoxContainerRef.current.style.top =
        searchNavRef.current.offsetTop + searchNavBounding.height + "px"
      searchBoxContainerRef.current.style.left =
        searchNavRef.current.offsetLeft -
        searchBoxBounding.width / 2 +
        searchIconBounding.width / 2 +
        "px"
    }

    setSearchOpenClass(defaultSearchOpenClass(!searchOpenCollapseIn))
    setSearchOpenCollapseIn(!searchOpenCollapseIn)
  }
  const ValidateSubmitUserSearch = e => {
    const searchText = searchBoxInputRef.current.value
    if (searchText === null || typeof searchText.toLowerCase() !== "string") {
      e.preventDefault()
      return false
    }

    if (searchText.trim().length < 1) {
      e.preventDefault()
      return false
    }

    searchIconClick(e)
    return true
  }

  const { color, rightLinks, leftLinks, brand, fixed, absolute } = props
  const [searchOpenCollapseIn, setSearchOpenCollapseIn] = useState(false)
  const [searchOpenClass, setSearchOpenClass] = useState(
    defaultSearchOpenClass(false)
  )
  const [searchOpenSearchInternal, setSearchOpenSearchInternal] = useState(true)

  const searchNavRef = useRef()
  const searchBoxContainerRef = useRef()
  const searchBoxInputRef = useRef()
  const searchBoxFormRef = useRef()

  const appBarClasses = classNames({
    [pageHeaderclasses.appBar]: true,
    [themeVariation]: true,
    [pageHeaderclasses[color]]: color,
    [pageHeaderclasses.absolute]: absolute,
    [pageHeaderclasses.fixed]: fixed,
  })
  function defaultSearchOpenClass(searchOpenCollapseInValue) {
    return classNames({
      [pageHeaderclasses.search]: true,
      [themeVariation]: true,
      active: searchOpenCollapseInValue,
    })
  }
  const brandComponent = (
    <Link
      to="/"
      className={pageHeaderclasses.title}
      title={`${brand} home`}
      aria-label={`${brand} home`}
    >
      <img src={props.siteLogo} alt={brand} role="presentation" />
    </Link>
  )
  const searchComponent = (
    <Button
      ref={searchNavRef}
      className={searchOpenClass}
      onClick={searchIconClick}
    >
      <FontAwesomeIcon
        className={pageHeaderclasses.searchIcon}
        icon={faSearch}
      />
    </Button>
  )

  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={pageHeaderclasses.container}>
        {leftLinks !== undefined ? brandComponent : null}
        <div className={pageHeaderclasses.flex}>
          {leftLinks !== undefined ? (
            <Hidden smDown implementation="css">
              {leftLinks}
            </Hidden>
          ) : (
            brandComponent
          )}
        </div>
        {searchComponent}
        <Paper
          elevation={4}
          ref={searchBoxContainerRef}
          className={pageHeaderclasses.searchBoxContainer}
        >
          <Collapse in={searchOpenCollapseIn}>
            <form
              ref={searchBoxFormRef}
              method="GET"
              action="/search"
              onSubmit={ValidateSubmitUserSearch}
            >
              <div>
                <InputBase
                  inputRef={searchBoxInputRef}
                  name="q"
                  placeholder="Search…"
                  classes={{
                    root: pageHeaderclasses.inputRoot,
                    input: classNames(pageHeaderclasses.inputInput, themeVariation),
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Switch
                            name="searchType"
                            checked={searchOpenSearchInternal}
                            onChange={evt =>
                              setSearchOpenSearchInternal(
                                !searchOpenSearchInternal
                              )
                            }
                            value={
                              searchOpenSearchInternal ? "internal" : "external"
                            }
                          />
                        }
                        label={<FormHelperText>Site Only</FormHelperText>}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={6}
                    className={classNames("submitContainer")}
                  >
                    <Button
                      type="submit"
                      color="primary"
                      aria-label="Submit Search"
                    >
                      Search
                    </Button>
                  </GridItem>
                </GridContainer>
                <small>Search powered by Google</small>
              </div>
            </form>
          </Collapse>
        </Paper>
        <Hidden smDown implementation="css">
          {rightLinks}
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classNames(pageHeaderclasses.drawerPaper, themeVariation),
          }}
          onClose={handleDrawerToggle}
        >
          <div className={pageHeaderclasses.appResponsive}>
            {leftLinks}
            {rightLinks}
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  )
}

PageHeader.defaultProp = {
  color: "white",
}

PageHeader.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
  }),
}
