import {
  title,
  backgroundColor,
  primaryColor,
} from "assets/jss/common/threemysticapes.js"

export const globalPageStyle = {
  "@global": {
    ".ReactModalPortal": {
      position: "relative",
      zIndex: 9000,
    },
    ".postListGrid": {
      "&.dark": {
        color: primaryColor.normal.dark.textColor,
      },
      "& > .MuiGridListTile-tile": {
        "& > a:first-of-type": {
          "& > img:first-of-type": {
            height: "100%",
          },
        },
      },
      "& .MuiGridListTileBar-titleWrap": {
        "& h3,h6": {
          marginTop: "0rem",
          marginBottom: "0rem",
          whiteSpace: "break-spaces",
        },
        "& .MuiGridListTileBar-subtitle": {
          "& h6": {
            height: "80px",
          },
        },
      },
    },
    ".cookieNotificationWrapper": {
      width: "100%",
      position: "fixed",
      bottom: "0px",
      left: "0px",
      zIndex: "9000",
      margin: "0 0 0 0",
      "& .cookieNotification": {
        paddingTop: "15px",
        paddingBottom: "15px",
      },

      "&.cookieNotificationClose": {
        bottom: "-500px",
        transition: "bottom 1s ease-in",
      },
    },
    "pre":{
      overflow:"auto",
    }
  },
  htmlRoot: {
    height: "100%",
    "&.dark": {
      "background-color": backgroundColor.dark.color,

      "& .MuiTypography-colorTextSecondary": {
        color: primaryColor.dark.dark.textColor,
      },

      "& .cookieNotificationWrapper": {
        backgroundColor: primaryColor.light.dark.textColor,
        color: primaryColor.light.dark.background,
      },
    },
  },
  page: {
    height: "100%",
    "&.dark": {
      "background-color": backgroundColor.dark.color,
      color: backgroundColor.dark.textColor,
      "& a": {
        color: backgroundColor.dark.textColor,
      },
    },
    "& > div:first-of-type": {
      height: "100%",
      "& > div:first-of-type": {
        height: "100%",
      },
    },
  },
  parallaxPage: {
    height: "30vh",
    maxHeight: "300px",
  },
  sectionBody: {},
  mainContent: {
    minHeight: "65vh",
    padding: "30px 0",
    "&.dark": {
      color: backgroundColor.dark.textColor,
    },
  },
  mainNavLinkActive: {
    "&:hover": {
      "&.dark": {
        backgroundColor: primaryColor.normal.dark.aHref.hover.backgroundColor,
      },
    },
  },
  mainNavDropDown: {
    "&.dark": {
      backgroundColor: primaryColor.light.dark.background,
    },
  },
  mainSubNavLink: {
    width: "100%",
  },
  notistackGDPR: {
    marginBottom: "100px",
  },
  card: {
    "& .cardTitle": {
      ...title,
      textAlign: "center",
    },

    "& .contactText": {
      backgroundColor: "#ffffff",
      paddingBottom: "10px",
      color: "#121212",
      "& label": {
        fontSize: ".75rem",
        color: "#121212",
      },
      "& div": {
        fontSize: ".75rem",
        backgroundColor: "#ffffff",
        color: "#121212",
      },
    },
    "& .SendButtonJustifyRight": {
      marginRight: "0px",
      marginLeft: "auto",
    },
  },
}

export default {
  globalPageStyle,
}
