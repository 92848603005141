import React from 'react';
import classNames from "classnames";
import PropTypes from "prop-types";

import PageBreadcrumb from "@components/layout/section/common/page/breadCrumbs.js";

PageMaincontent.propTypes = {
    props: PropTypes.object,
}

function GetObjectChildValue(object, child) {
    if (object === null || typeof object === 'undefined') {
        return null;
    }

    if (object[child] === null || typeof object[child] === 'undefined') {
        return null;
    }

    return object[child];
}

function CheckChildExists(object, child){
    if (object === null || typeof object === 'undefined'){
        return false;
    }

    if (object[child] === null || typeof object[child] === 'undefined') {
        return false;
    }

    return true;
}

export default function PageMaincontent({ children, pageNode, classes, classesPage, classesPost, location, pageContext, ...props}) {

    const mainClassNames = classNames({
        [GetObjectChildValue(classesPage, "mainContent")]: CheckChildExists(classesPage, "mainContent"),
        [GetObjectChildValue(classesPost, "mainContent")]: CheckChildExists(classesPost, "mainContent"),
    })
    // {children}
    return (
      <main className={mainClassNames} role="main">
        <PageBreadcrumb
          classes={classes}
          classesPage={classesPage}
          classesPost={classesPost}
          location={location}
          pageNode={pageNode}
          pageContext={pageContext}
        />
        <div>{children}</div>
      </main>
    )
}