import {
    primaryColor,
} from "assets/jss/common/threemysticapes.js";

const breadCrumbStyle = {
    breadcrumb: {
        "& a": {
            fontSize: ".65rem",
            padding: "10px 10px 10px 10px",
        },
        "& p": {
            fontSize: ".65rem",
        },
        "& li, p": {
            fontSize: ".65rem",
        },
        "&.dark":{
            "& a":{
                color: primaryColor.normal.dark.textColor,
            },
            "& a:hover": {
                backgroundColor: primaryColor.normal.dark.aHref.hover.backgroundColor,
                borderRadius: "5px 5px 5px 5px"
            },
            "& li, p": {
                color: primaryColor.normal.dark.textColor,
            }
        }
    },
    
};

export default breadCrumbStyle;
