import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types";
import classNames from "classnames";

import { useStaticQuery, graphql } from "gatsby"

import 'react-image-lightbox/style.css';

import ThreeMysticApesThemeHelper from "three_mystic_apes_extension/util/themeHelper.js";


LayoutHeader.propTypes = {
    props: PropTypes.object,
}

//GTag Code
// (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
// new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
// j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
// 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
// })(window,document,'script','dataLayer','GTM-WF2NJHR');



export function LayoutHeader({ children, pageNode, ...props }) {
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            defaultThemeName
            componentKeys {
              google {
                analytics
                adSense
              }
            }
          }
        }
      }
    `
    )
    const { classesPage } = props;    
    const themeVariation = ThreeMysticApesThemeHelper.GetThemeVariation();

    const bodyClassStyles = [classesPage.page, themeVariation];

    return (
        <Helmet htmlAttributes={{ class: classNames([classesPage.htmlRoot, themeVariation]) }}>
            <body className={classNames(bodyClassStyles)} />
        </Helmet>
    )
}