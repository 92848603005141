//copy contents from assets/jss/common/material-kit-react.js
//Dark theme reference
//https://www.bootstrapdash.com/demo/material-admin-dark/jquery/template/demo_1/index.html
//material components
//https://material-components.github.io/

import React, {useState} from "react"
import { Helmet } from "react-helmet"
import classNames from "classnames"
import PropTypes from "prop-types"

import { useStaticQuery, graphql } from "gatsby"
import GridContainer from "@components/Grid/GridContainer.js"
import GridItem from "@components/Grid/GridItem.js"
import Typography from "@material-ui/core/Typography"
import Button from "@components/customButtons/button.js"

// @material-ui/icons

import "fontsource-roboto"
//@FontAwsome
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"

// core components
import { LayoutHeader } from "@components/layout/section/common/layoutHeader.js"
import LayoutSectionBody from "@components/layout/section/common/body.js"
import PageHeader from "@components/layout/header/header.js"
import PageHeaderLinks from "@components/layout/header/headerLinks.js"
import PageFooter from "@components/layout/footer/footer.js"
import Parallax from "@components/Parallax/Parallax.js"

import { SnackbarProvider } from "notistack"

import SEO from "@components/seo/seo"

import ThreeMysticApesThemeHelper from "three_mystic_apes_extension/util/themeHelper.js"

library.add(fab)
const dashboardRoutes = []

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageNode: PropTypes.object.isRequired,
  props: PropTypes.object,
}

//GTag Code
// (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
// new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
// j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
// 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
// })(window,document,'script','dataLayer','GTM-WF2NJHR');

export default function Layout({
  children,
  pageNode,
  classes,
  classesPage,
  classesPost,
  location,
  ...props
}) {
  const { site, siteLogo } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultThemeName
            componentKeys {
              google {
                analytics
              }
            }
          }
        }
        siteLogo: file(
          relativePath: {
            eq: "assets_threemysticapes/image/global/threemysticapes_llc_white.png"
          }
        ) {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    `
  )
 
  const [closeCookiePolicy, setCloseCookiePolicy] = useState(
    ThreeMysticApesThemeHelper.CookiePolicyUserDisplay()
  )
  
  const cookiePolicyDisplayClasses = classNames({
    "cookieNotificationWrapper": true,
    "cookieNotificationClose": closeCookiePolicy,
  })

  function ProcessCloseCookieAlert(e) {
    setCloseCookiePolicy(true);
    ThreeMysticApesThemeHelper.CookiePolicyUserDisplay(true)

  }

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      classes={{
        variantSuccess: classNames({
          [classesPage.notistackGDPR]: !closeCookiePolicy,
        }),
        variantError: classNames({
          [classesPage.notistackGDPR]: !closeCookiePolicy,
        }),
        variantWarning: classNames({
          [classesPage.notistackGDPR]: !closeCookiePolicy,
        }),
        variantInfo: classNames({
          [classesPage.notistackGDPR]: !closeCookiePolicy,
        }),
      }}
    >
      <LayoutHeader 
      pageNode={pageNode}
        classesPage={classesPage}
        {...props}
      />

      <SEO
        pageNode={pageNode}
        siteLogo={siteLogo.childImageSharp.original.src}
      />

      <section className="header">
        <PageHeader
          location={location}
          classes={classes}
          classesPage={classesPage}
          color="transparent"
          routes={dashboardRoutes}
          brand="3 Mystic Apes LLC"
          siteLogo={siteLogo.childImageSharp.original.src}
          rightLinks={
            <PageHeaderLinks
              location={location}
              pageNode={pageNode}
              classes={classes}
              classesPage={classesPage}
            />
          }
          fixed
          changeColorOnScroll={{
            height: 15,
            color: "dark",
          }}
          {...props}
        />
        <Parallax
          filter
          image={require("assets/image/background/space.jpg")}
          pageClass={classesPage["parallaxPage"]}
        ></Parallax>
      </section>

      <LayoutSectionBody
        classes={classes}
        classesPage={classesPage}
        classesPost={classesPost}
        pageNode={pageNode}
        location={location}
      >
        <noscript>
          You currently have JavaScript disabled. In order make use of all the
          sites features, JavaScript is required.
        </noscript>
        {children}
      </LayoutSectionBody>

      <section className="footer" role="contentinfo">
        <PageFooter />
      </section>
      <GridContainer justify="center" className={cookiePolicyDisplayClasses}>
        <GridItem xs={false} sm={false} md={1}>
          &nbsp;
        </GridItem>
        <GridItem xs={10} sm={10} md={10}>
          <GridContainer justify="center" className="cookieNotification">
            <GridItem xs={10} sm={10} md={10}>
              <Typography component="div">
                This site stores cookies on your computer to improve your
                browsing experience. By using this site, you agree to our
                Privacy Policy.
              </Typography>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <Button
                color="primary"
                onClick={ProcessCloseCookieAlert}
                aria-label="Close and Agree with Privacy Policy"
              >
                Close
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={false} sm={false} md={1}>
          &nbsp;
        </GridItem>
      </GridContainer>
    </SnackbarProvider>
  )
}
