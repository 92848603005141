/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function GetDescription(pageNode, siteMetadataDescription) {
  if (pageNode === null || typeof pageNode === "undefined") {
    return siteMetadataDescription
  }

  if (
    pageNode.context.description !== null &&
    typeof pageNode.context.description !== "undefined"
  ) {
    if (pageNode.context.description.length > 5) {
      return pageNode.context.description
    }
  }

  if (
    pageNode.context.excerpt !== null &&
    typeof pageNode.context.excerpt !== "undefined"
  ) {
    if (pageNode.context.excerpt.length > 5) {
      return pageNode.context.excerpt
    }
  }

  return siteMetadataDescription
}

function GetImage(pageNode, siteMetadata, siteLogo) {
  if (
    pageNode.context.hero !== null &&
    typeof pageNode.context.hero !== "undefined"
  ) {
    return pageNode.context.hero.publicURL
  }
  return siteLogo
}
function SEO({ pageNode, lang, meta, ...props }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            
          }
        }
      }
    `
  )

  const metaDescription = GetDescription(
    pageNode,
    site.siteMetadata.description
  )
  const title = pageNode == null ? "" : pageNode.context.title
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: GetImage(pageNode, props.siteLogo),
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
}

SEO.propTypes = {
  pageNode: PropTypes.object,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
