/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useStaticQuery, graphql, Link } from "gatsby"

import styles from "assets/jss/components/layout/footer/style.js";

const useStyles = makeStyles(styles);

export default function PageFooter(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const data = useStaticQuery(
    graphql`
      query {
        footer: allThreeMysticApesPage(sort: {fields: page___pageKey, order: ASC}, filter: {page: {includeMenu: {in: "footer"}}}) {
          edges {
            node {
              page {
                title
                slug
                ariaLabel
              }
            }
          }
        }
        site {
          siteMetadata {
            linkedin
            twitter
          }
        }
      }      
    `
  )


  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          
        </div>
        <div className={classes.right}>
          <List className={classes.list}>
            {data.footer.edges.map(({ node }, index) => (
              <ListItem className={classes.inlineBlock} key={index}>
                <Link
                  to={node.page.slug}
                  className={classes.block}
                  activeClassName={classes.navLinkActive}
                  role="menuitem"
                  title={node.page.title}
                  aria-label={node.page.ariaLabel}
                >
                  {node.page.title}
                </Link>
              </ListItem>
            ))}
            <ListItem className={classes.inlineBlock}>
              &copy; {1900 + new Date().getYear()} 
            </ListItem>
          </List>
        </div>
      </div>
    </footer>
  );
}

PageFooter.propTypes = {
  whiteFont: PropTypes.bool
};
