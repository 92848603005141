import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import { Link } from "gatsby"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Typography from "@material-ui/core/Typography"
import { useStaticQuery, graphql } from "gatsby"

import breadCrumbStyle from "assets/jss/components/layout/section/page/breadCrumbs.js"

import ThreeMysticApesUtil from "three_mystic_apes_extension/util/utilities.js"
import ThreeMysticApesThemeHelper from "three_mystic_apes_extension/util/themeHelper.js"

const useStyleBreadCrumb = makeStyles(breadCrumbStyle)

PageBreadcrumb.propTypes = {
  props: PropTypes.object,
}

function IsNodeSlugCrum({ pageNode, location }) {
  if (location === null || typeof location === "undefined") {
    return false
  }

  if (location.state === null || typeof location.state === "undefined") {
    return false
  }

  if (
    location.state.crum === null ||
    typeof location.state.crum === "undefined"
  ) {
    return false
  }

  return true
}
function GetNodeSlug({ pageNode, location }) {
  return IsNodeSlugCrum({ pageNode, location })
    ? location.state.crum.pageNode.slug
    : pageNode.slug
}

function GetYearMonth({ pageContext, pageNode, location }) {
  if (!IsNodeSlugCrum({ pageNode, location })) {
    return pageContext.yearMonth
  }
  const nodeStartDate = ThreeMysticApesUtil.GetDateTime(
    pageNode.context.startDate
  )
  return {
    year: nodeStartDate.getFullYear(),
    month: nodeStartDate.getMonth() + 1,
  }
}

function GetContextTitle({ pageNode, pageContext, location }) {
  return IsNodeSlugCrum({ pageNode, location })
    ? location.state.crum.postDynamicTitle === null ||
      typeof location.state.crum.postDynamicTitle === "undefined" ||
      location.state.crum.postDynamicTitle === ""
      ? location.state.crum.data
      : location.state.crum.postDynamicTitle
    : pageContext.pageNode !== null &&
      typeof pageContext.pageNode !== "undefined"
    ? pageContext.pageNode.context.title
    : null
}

function GetContext({ pageNode, pageContext, location }) {
  return IsNodeSlugCrum({ pageNode, location })
    ? location.state.crum.postGroupValue
    : pageContext.data
}

function GetLocationPathName(location) {
  if (location === null || typeof location === "undefined") {
    return null
  }
  if (location.pathname === null || typeof location.pathname === "undefined") {
    return null
  }

  return location.pathname
}

function ProcessCrumbBlogSection({ context, nodeSlug }) {
  const nodeSlugTrimmed = ThreeMysticApesUtil.TrimURLPath(nodeSlug)
  if (nodeSlugTrimmed === null || typeof nodeSlugTrimmed === "undefined") {
    return null
  }

  const nodeSlugParsed = nodeSlugTrimmed.split("/")
  const blogSection = nodeSlugParsed[1]

  const sectionData = {
    tag: "Post by Tag",
    series: "Post by Series",
    mysticape: "Post by Mystic Ape",
  }

  if (
    sectionData[blogSection] !== null &&
    typeof sectionData[blogSection] !== "undefined"
  ) {
    if (context === null || typeof context === "undefined") {
      return null
    }
    return (
      <Link
        key={`/blog/${blogSection}`}
        to={`/blog/${blogSection}`}
        title={sectionData[blogSection]}
        aria-label={sectionData[blogSection]}
      >
        {sectionData[blogSection]}
      </Link>
    )
  }
  return null
}

function GetCrumb({ pageNode, pageContext, location }) {
  const nodeSlug = GetNodeSlug({ pageNode, location })
  const locationSlugRaw = GetLocationPathName(location)
  if (locationSlugRaw === null) {
    return null
  }

  const locationSlug = ThreeMysticApesUtil.TrimURLPath(locationSlugRaw)
  const returnChildren = []
  if (locationSlug.startsWith("/blog/")) {
    returnChildren.push(
      <Link key={`/blog`} to={`/blog`} title={`Blog`} aria-label={`Blog`}>
        Blog
      </Link>
    )
  }

  const context = GetContext({ pageNode, pageContext, location })
  const sectionCrum = ProcessCrumbBlogSection({ context, nodeSlug })
  if (sectionCrum !== null) {
    returnChildren.push(sectionCrum)
  }

  const yearMonth = GetYearMonth({ pageNode, pageContext, location })

  const contextTitle = GetContextTitle({ pageNode, pageContext, location })
  if (
    IsNodeSlugCrum({ pageContext, location }) &&
    contextTitle !== null &&
    typeof contextTitle !== "undefined"
  ) {
    returnChildren.push(
      <Link
        key={location.state.crum.pageNode.slug}
        to={location.state.crum.locationSlug}
        title={context}
        aria-label={context}
      >
        {GetContextTitle({ pageNode, pageContext, location })}
      </Link>
    )
  }

  if (
    yearMonth !== null &&
    typeof yearMonth !== "undefined" &&
    (sectionCrum === null || typeof sectionCrum === 'undefined')
  ) {
    if (yearMonth.month > 0) {
      returnChildren.push(
        <Link
          key={`/blog/${yearMonth.year}`}
          to={`/blog/${yearMonth.year}`}
          title={`Posts - ${yearMonth.year}`}
          aria-label={`Posts - ${yearMonth.year}`}
        >
          {yearMonth.year}
        </Link>
      )
    }
    if (IsNodeSlugCrum({ pageContext, location })) {
      const month =
        yearMonth.month > 9 ? yearMonth.month : "0" + yearMonth.month
      returnChildren.push(
        <Link
          key={`/blog/${yearMonth.year}/${month}`}
          to={`/blog/${yearMonth.year}/${month}`}
          title={`Posts - ${yearMonth.year}/${month}`}
          aria-label={`Posts - ${yearMonth.year}/${month}`}
        >
          {month}
        </Link>
      )
    }
  }

  return returnChildren
}

function GetCrumbTitle({ pageNode, pageContext, location }) {
  const context = GetContext({ pageContext, location })

  if (
    pageContext.dynamicTitle !== null &&
    typeof pageContext.dynamicTitle !== "undefined"
  ) {
    const yearMonth = GetYearMonth({ pageNode, pageContext, location })
    if (yearMonth !== null && typeof yearMonth !== "undefined") {
      if (yearMonth.month > 0) {
        return yearMonth.month > 9 ? yearMonth.month : "0" + yearMonth.month
      }
    }
    return pageContext.dynamicTitle
  }

  if (
    context !== null &&
    typeof context !== "undefined" &&
    !IsNodeSlugCrum({ pageContext, location })
  ) {
    return context
  }

  return pageNode.context.title
}

export default function PageBreadcrumb({
  pageNode,
  pageContext,
  location,
  ...props
}) {
  const { site, siteLogo } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
          }
        }
        siteLogo: file(
          relativePath: {
            eq: "assets_threemysticapes/image/global/threemysticapes_llc_white.png"
          }
        ) {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    `
  )

  const breadCumbClasses = useStyleBreadCrumb()
  const themeVariation = ThreeMysticApesThemeHelper.GetThemeVariation()
  if (pageNode.slug === "/") {
    return null
  }

  const breadCumbClassName = classNames({
    [breadCumbClasses.breadcrumb]: true,
    [themeVariation]: true,
  })

  const crumData = GetCrumb({ pageNode, pageContext, location });

  const schemaElemItems = crumData.map((item, index) => {
    return {
      "@type": "ListItem",
      position: index + 2,
      item: {
        "@id": item.props.to,
        name: "3 Mystic Apes LLC",
      },
    }
  });
  schemaElemItems.splice(0, 0, {
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": site.siteMetadata.siteUrl,
      "name": "3 Mystic Apes LLC",
      "image": site.siteMetadata.siteUrl + siteLogo.childImageSharp.original.src
    }
  });
  schemaElemItems.push({
    "@type": "ListItem",
    "position": crumData.length + 2,
    "item": {
      "@id": site.siteMetadata.siteUrl + pageNode.slug,
      "name": GetCrumbTitle({ pageNode, pageContext, location }),
      "image": pageNode.context.hero
    }
  });

  return (
    <Fragment>
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": ${JSON.stringify(schemaElemItems)}
          }
        `}
        </script>
      </Helmet>
      <Breadcrumbs className={breadCumbClassName} aria-label="breadcrumb">
        <Link to={`/`} title={`Home`} aria-label={`Home`}>
          Home
        </Link>
        {crumData}
        <Typography color="textPrimary">
          {GetCrumbTitle({ pageNode, pageContext, location })}
        </Typography>
      </Breadcrumbs>
    </Fragment>
  )
}
