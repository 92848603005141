import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

/*eslint-disable*/
import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Tooltip from "@material-ui/core/Tooltip"

// core components
import CustomDropdown from "@components/customDropdown/customDropdown.js"
import Button from "@components/customButtons/button.js"

import styles from "assets/jss/components/layout/header/linkStyle.js"

import { useStaticQuery, graphql, Link } from "gatsby"

import ThreeMysticApesUtil from "three_mystic_apes_extension/util/utilities.js"
import ThreeMysticApesThemeHelper from "three_mystic_apes_extension/util/themeHelper.js"

const useStyles = makeStyles(styles)

PageHeaderHeaderLinks.propTypes = {
  pageNode: PropTypes.object.isRequired,
}

function GetPageSlug({ pageNode }) {
  if (pageNode === null || typeof pageNode === "undefined") {
    return ""
  }
  return pageNode.slug !== null && typeof pageNode !== "undefined"
    ? pageNode.slug
    : ""
}

function GetSubNavItems({ menuPagesNotMain, currentNode }) {
  return menuPagesNotMain.filter(({ node }) => {
    if (
      currentNode.page.subNav === null ||
      typeof currentNode.page.subNav === "undefined"
    ) {
      return false
    }

    return node.page.includeMenu.indexOf(currentNode.page.subNav) > -1
  })
}

function CurrentUrlSubNavItem({ location, subNavItems, nodeToLink }) {
  if (nodeToLink.slug === ThreeMysticApesUtil.TrimURLPath(location.pathname)) {
    return 0
  }

  let item = subNavItems.find(node => node.slug === location.pathname)
  if (item !== null && typeof item !== "undefined") {
    return subNavItems.indexOf(item) + 1
  }

  item = subNavItems.find(node => location.pathname.startsWith(node.slug))
  if (item !== null && typeof item !== "undefined") {
    return subNavItems.indexOf(item) + 1
  }

  return -1;
}

function AddLinkToNav({
  location,
  nodeToLink,
  pageSlug,
  pageHeaderLinksclasses,
  classes,
  classesPage,
  themeVariation,
  subNavItems,
}) {
  if (classesPage === null || typeof classesPage === "undefined") {
    classesPage = {}
  }
  if (classes === null || typeof classes === "undefined") {
    classes = {}
  }

  const isCurrentPageActive = pageSlug === nodeToLink.slug
  const linkClassName = classNames({
    [pageHeaderLinksclasses.navLink]: true,
    [themeVariation]: true,
  })

  if (nodeToLink.page.includeMenu.indexOf("mainMenu") > -1) {
    if (
      nodeToLink.page.subNav === null ||
      typeof nodeToLink.page.subNav === "undefined" ||
      subNavItems.length < 1
    ) {
      return (
        <Link
          to={nodeToLink.slug}
          className={linkClassName}
          activeClassName={pageHeaderLinksclasses.navLinkActive}
          role="menuitem"
          title={nodeToLink.page.title}
          aria-label={nodeToLink.page.ariaLabel}
        >
          {nodeToLink.page.title}
        </Link>
      )
    }

    const selectedItemIndex = CurrentUrlSubNavItem({
      location,
      subNavItems,
      nodeToLink,
    })

    subNavItems.splice(0, 0, { node: nodeToLink })

    const linkDropDownClassName = classNames({
      [pageHeaderLinksclasses.navLink]: true,
      [themeVariation]: true,
      [pageHeaderLinksclasses.navLinkActive]: isCurrentPageActive,
    })

    return (
      <CustomDropdown
        noLiPadding
        buttonText={nodeToLink.page.title}
        buttonProps={{
          className: linkDropDownClassName,
          color: "transparent",
          href: nodeToLink.slug,
        }}
        dropDownProps={{
          dropDownClass: classesPage.mainNavDropDown,
        }}
        dropdownList={subNavItems.map(({ node }, index) => {
          const subNavSlug = GetPageSlug({ node })

          const linkDropDownLinkClassName = classNames({
            [pageHeaderLinksclasses.navLink]: true,
            [themeVariation]: true,
            [classesPage.mainSubNavLink]:
              classesPage.mainSubNavLink !== null &&
              typeof classesPage.mainSubNavLink !== "undefined",
            [pageHeaderLinksclasses.navLinkActive]: index === selectedItemIndex,
          })

          return (
            <Link
              to={node.slug}
              className={linkDropDownLinkClassName}
              activeClassName={pageHeaderLinksclasses.navLinkActive}
              role="menuitem"
              title={node.page.title}
              aria-label={node.page.ariaLabel}
            >
              {node.page.title}
            </Link>
          )
        })}
      />
    )
  }

  return null
}

export default function PageHeaderHeaderLinks({
  pageNode,
  classes,
  classesPage,
  location,
  ...props
}) {
  const data = useStaticQuery(
    graphql`
      query {
        mainMenus: allThreeMysticApesPage(
          sort: { fields: page___pageKey, order: ASC }
          filter: { page: { includeMenu: { in: ["mainMenu", "blog"] } } }
        ) {
          edges {
            node {
              slug
              page {
                title
                slug
                includeMenu
                subNav
              }
            }
          }
        }
        site {
          siteMetadata {
            linkedin
            twitter
          }
        }
      }
    `
  )
  const themeVariation = ThreeMysticApesThemeHelper.GetThemeVariation()
  const pageSlug = GetPageSlug({ pageNode })

  const pageHeaderLinksclasses = useStyles()

  const listClassName = classNames({
    [pageHeaderLinksclasses.list]: true,
    [themeVariation]: true,
  })
  const listItemClassName = classNames({
    [pageHeaderLinksclasses.listItem]: true,
    [themeVariation]: true,
  })
  const menuPages = data.mainMenus.edges
  const menuPagesNotMain = menuPages.filter(({ node }) => {
    if (
      node.page.includeMenu === null ||
      typeof node.page.includeMenu === "undefined"
    ) {
      return false
    }

    return (
      node.page.includeMenu.length > 1 ||
      node.page.includeMenu.indexOf("mainMenu") < 0
    )
  })

  return (
    <nav role="navigation">
      <List className={listClassName}>
        {menuPages.map(({ node }, index) => (
          <ListItem className={listItemClassName} key={index}>
            {AddLinkToNav({
              location,
              nodeToLink: node,
              pageSlug,
              pageHeaderLinksclasses,
              classes,
              classesPage,
              themeVariation,
              subNavItems: GetSubNavItems({
                currentNode: node,
                menuPagesNotMain,
              }),
            })}
          </ListItem>
        ))}

        <ListItem className={listItemClassName}>
          <Tooltip
            id="social-linkedin"
            title="linkedin"
            aria-label="linkedin"
            placement={
              typeof window !== "undefined" && window.innerWidth > 959
                ? "top"
                : "left"
            }
            classes={{ tooltip: pageHeaderLinksclasses.tooltip }}
          >
            <Button
              href={`https://www.linkedin.com/in/${data.site.siteMetadata.linkedin}/`}
              target="_blank"
              color="transparent"
              className={pageHeaderLinksclasses.navLink}
            >
              <FontAwesomeIcon
                icon={["fab", "linkedin"]}
                aria-hidden="true"
                size="2x"
              />
            </Button>
          </Tooltip>
        </ListItem>
        <ListItem className={listItemClassName}>
          <Tooltip
            id="social-twitter"
            title="twitter"
            aria-label="twitter"
            placement={
              typeof window !== "undefined" && window.innerWidth > 959
                ? "top"
                : "left"
            }
            classes={{ tooltip: pageHeaderLinksclasses.tooltip }}
          >
            <Button
              href={`https://www.twitter.com/${data.site.siteMetadata.twitter}/`}
              target="_blank"
              color="transparent"
              className={pageHeaderLinksclasses.navLink}
            >
              <FontAwesomeIcon
                icon={["fab", "twitter"]}
                aria-hidden="true"
                size="2x"
              />
            </Button>
          </Tooltip>
        </ListItem>
      </List>
    </nav>
  )
}
