import React from "react"
import PropTypes from "prop-types";
import classNames from "classnames";
// import { makeStyles } from "@material-ui/core/styles";

// import Paper from '@material-ui/core/Paper';

// import { layoutSectionBody } from "assets/jss/views/layout/section/body.js";

// const useStyles = makeStyles(layoutSectionBody);

LayoutSectionBody.propTypes = {
    children:PropTypes.node,
    props: PropTypes.object,
}




export default function LayoutSectionBody({ children, pageNode, classes, classesPage, classesPost, location,  ...props }) {

    return (        
        <section className={classesPage["sectionBody"]}>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    {children}
                </div>
            </div>
      </section>
    )
}