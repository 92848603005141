import {
  backgroundColor,
  container,
  defaultFont,
  primaryColor,
  notificationColor,
  otherColor,
  transition,
  boxShadow,
  drawerWidth,
} from "assets/jss/common/threemysticapes.js"

import spaceImg from "assets/image/background/space.jpg"
import { fade } from "@material-ui/core/styles"

const headerPageStyle = theme => ({
  appBar: {
    display: "flex",
    border: "0",
    borderRadius: "3px",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: "#555",
    width: "100%",
    backgroundColor: "#fff",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
    transition: "all 150ms ease 0s",
    alignItems: "center",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    position: "relative",
    zIndex: "unset",
  },
  absolute: {
    position: "absolute",
    zIndex: "1100",
  },
  fixed: {
    position: "fixed",
    zIndex: "1100",
  },
  container: {
    ...container,
    minHeight: "50px",
    flex: "1",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap",
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    padding: "8px 16px",
    letterSpacing: "unset",
    "&:hover,&:focus": {
      color: "inherit",
      background: "transparent",
    },
    "& img": {
      "max-width": "300px",
      "max-height": "100px",
    },
  },
  appResponsive: {
    margin: "20px 10px",
  },
  primary: {
    backgroundColor: primaryColor.normal.dark.background,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)",
  },
  info: {
    backgroundColor: notificationColor.info.dark.color,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)",
  },
  success: {
    backgroundColor: notificationColor.success.dark.color,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46)",
  },
  warning: {
    backgroundColor: notificationColor.warning.dark.color,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46)",
  },
  danger: {
    backgroundColor: notificationColor.error.dark.color,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)",
  },
  rose: {
    backgroundColor: otherColor.rose.dark.color,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46)",
  },
  transparent: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
    paddingTop: "25px",
    color: "#FFFFFF",
  },
  dark: {
    border: "0",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: backgroundColor.dark.textColor,
    backgroundColor: backgroundColor.dark.color + " !important",
    backgroundImage: "url('" + spaceImg + "')",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)",
    "border-bottom-left-radius": "75px",
    "border-bottom-right-radius": "75px",
  },
  white: {
    border: "0",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: "#555",
    backgroundColor: "#fff !important",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: drawerWidth,
    ...boxShadow,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    ...transition,
    "&.dark": {
      backgroundColor: primaryColor.light.dark.background,
    },
  },
  search: {
    position: "relative",
    color: primaryColor.light.dark.textColor,
    fontSize: "1rem",
    borderRadius: theme.shape.borderRadius,
    padding: "0.9375rem",
    backgroundColor: fade(theme.palette.common.white, 0.05),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    "&.active": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    marginRight: `calc(${theme.spacing(4)}px)`,
    cursor: "pointer",
  },
  searchIcon: {
    pointerEvents: "none",
    alignItems: "center",
    justifyContent: "center",
    color: primaryColor.light.dark.textColor,
  },
  searchBoxContainer: {
    position: "absolute",
    width: "300px",
    "& > div:first-child > div:first-child": {
      padding: "10px 10px 10px 10px",
    },
    "& .submitContainer": {
      textAlign: "right",
    },
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    width: "100%",
    "&.dark": {
      borderBottom: "solid 1px " + primaryColor.light.dark.background,
    },
  },
})

export default headerPageStyle
